import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [4,2];

export const dictionary = {
		"/(marketing-site)": [17,[4]],
		"/(admin)/account": [~7,[2]],
		"/(admin)/age-checks": [~8,[2]],
		"/(admin)/age-checks/new": [9,[2]],
		"/(marketing-site)/(static)/faqs": [18,[4,5]],
		"/(auth)/login": [~10,[3]],
		"/(auth)/logout": [~11,[3]],
		"/(marketing-site)/(static)/pricing": [19,[4,5]],
		"/(marketing-site)/(static)/privacy": [20,[4,5]],
		"/(auth)/reset-password": [~12,[3]],
		"/(auth)/reset-password/sent": [14,[3]],
		"/(auth)/reset-password/[token]": [~13,[3]],
		"/(auth)/signup": [15,[3]],
		"/(marketing-site)/(static)/terms": [21,[4,5]],
		"/(auth)/verify": [~16,[3]],
		"/[slug]": [~22,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';